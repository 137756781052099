body#rtl {

    .login_page {
        & .login_form {
            right: unset;
            left: 0;

            .eye_icon {
                right: unset !important;
                left: 10px;
            }
        }
    }

    #sidebar-menu {
        & .metismenu li a {
            & img {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }

    .dashboard_common_date {
        & .filter_period {
            margin-left: 0;
            margin-right: 16px;
        }
    }

    .reset_btn.dashboard_reset_btn {
        margin-right: 0 !important;
        margin-left: 20px;
    }

    .set_right {
        left: 10px;
        right: unset !important;
    }

    .modal_close_btn {
        right: unset;
        left: 15px;
    }

    .dashboard_filter_flatpickr {
        top: 152px !important;
        left: 24px !important;
        right: unset !important;
    }

    .select_wrapper::after {
        right: unset;
        left: 10px;
    }
}